<template>
	<div>
		<div class="row box">
			<div class="col-12">
				<h3 class="mb-4">{{ $t('monte.conditions_liaison_etalon') }}</h3>

				<CustomTable
					id_table="type_monte_distribute"
					ref="table_type_monte"
					:items="contract_config_type_monte_formatted"
					:busy="table_busy_type_monte"
					primaryKey="contractconfigtypemonte_id"
					:columsAdd="typeMonteAddColumns"
					:rawColumns="columsAdd"
					:externSlotColumns="['available_ca']"
					:hrefsRoutes="config_table_hrefs"
				>
					<template v-slot:[`custom-slot-cell(same_as_parent)`]="{ data }">
						<font-awesome-icon class="text-success" v-if="data.same_as_parent" :icon="['fal', 'check-circle']" />
						<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
					</template>
					<template v-slot:[`custom-slot-cell(available_ca)`]="{ data }">
						<b-form-checkbox
							class="custom-control custom-checkbox float-right mr-2"
							v-model="data.contractconfigtypemonte_available_ca"
							@input="switchAvailableCa(data)"
							name="check-default-ca" 
							switch
						>
						</b-form-checkbox>
					</template>
				</CustomTable>
			</div>
		</div>

		<div class="row box">
			<div class="col-12">
				<h3 class="mb-4">{{ $t('monte.contract_config_lisaison_etalons') }}</h3>

				<div class="row mb-4" v-for="(type_monte, monte_index) in details" :key="type_monte.contractconfigglobaltypemonte_id">
					<div class="col-12" v-show="contractconfigglobaltypemonte_id == type_monte.contractconfigglobaltypemonte_id">
						<h4 class="mb-4">{{ $t(type_monte.type_monte.contracttypemonte_label) }}</h4>
						
						<div class="row">
							<div class="col-4"></div>
							<div class="col mb-3" v-for="conditions in type_monte.config_conditions_global" :key="conditions.contractconfigglobalconditions_id">
								<h5>{{ $t(conditions.conditions.contractconditions_label) }}</h5>
							</div>
						</div>
						<div class="row align-items-center" v-for="(horse, horse_index) in type_monte.horses" :key="horse.horse.horse_id" :class="{'light-grey': horse_index % 2 === 0, 'white': horse_index % 2 !== 0 }">
							<div class="col-4">
								<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: horse.horse.horse_id }}">
									{{ horse.horse.horse_nom }}
								</router-link>
							</div>
							<div class="col" 
								v-show="all_type_monte[type_monte.contractconfigglobaltypemonte_typemonte] != undefined && all_type_monte[type_monte.contractconfigglobaltypemonte_typemonte].indexOf(condition.contractconfigconditions_conditions) > -1" 
								v-for="(condition, index_cdt) in horse.conditions" 
								:key="condition.contractconfigconditions_id">
								<div class="form-group" v-for="(article, index_article) in condition.articles_valides" :key="article.contractconfigconditionsarticles_id">
									<div class="row">
										{{ article.articles.articles_label }}
									</div>
									<div class="row">
										<div class="col-4 px-0">
											<DecimalInput v-model="article.contractconfigconditionsarticles_htunit" @change="setHTCondition(monte_index, horse_index, index_cdt, index_article, article.contractconfigconditionsarticles_htunit)"/>
										</div>
										<div class="col-4 px-1">
											<b-input type="text" class="form-control" v-model="article.contractconfigconditionsarticles_qte" @change="setQteCondition(monte_index, horse_index, index_cdt, index_article, article.contractconfigconditionsarticles_qte)"/>
										</div>
										<div class="col-4 px-0">
											<b-input v-if="show_ht" type="text" class="form-control" v-model="article.contractconfigconditionsarticles_ht" :disabled="true"/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-6 col-lg-2 m-auto">
						<b-button block pill variant="primary" v-if="!table_busy_conditions" @click="save_conditions"><font-awesome-icon v-if="processing_conditions" :icon="['fas', 'spinner']" pulse /> {{ $t("global.sauvegarder") }}</b-button>
					</div>
				</div>
			</div>
		</div>

		<b-modal ref="modal" hide-footer>
			<template v-slot:modal-title>
				{{ $t("action.add_stallion_to_conditions") }}
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">
				<form @submit.prevent="checkForm" class="mb-4">
					<div class="row">
						<div class="col-12" v-if="form_message !== ''">
							<ErrorAlert :messageI18n="form_message" />
						</div>
						
						<div class="col-12">
							<div class="form-group">
								<label for="horse_id">{{ $t('horse.horse') }}</label>
								<e-select
									v-model="horse"
									track-by="horse_id"
									label="horse_nom"
									:placeholder="labelTitleHorse"
									:selectedLabel="selectedLabel"
									:options="horses"
									:searchable="true"
									:multiple="true"
									:allow-empty="false"
									:show-labels="false"
									:class="{ 'is-invalid': errors && errors.indexOf('horse') > -1 }"
								>
									<template slot="singleLabel" slot-scope="{ option }">{{ option.horse_nom }}</template>
									<template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>
							</div>
						</div>
					</div>
				</form>

				<div class="col-8 m-auto">
					<b-button block pill variant="primary" @click="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
				</div>
			</div>
		</b-modal>
	</div>
</template>
<style scoped>
	.light-grey{
		background-color: #f4f4f4;
	}
</style>
<script type="text/javascript">
	import Navigation from "@/mixins/Navigation.js"
	import ModelMixin from "@/mixins/Contract.js"
	import _sortBy from 'lodash/sortBy'
	import ContractMixin from "@/mixins/Contract.js"
	import {EventBus} from 'EventBus'

	export default {
		name: "ConditionsDistribute",
		props: ['contractconfigglobaltypemonte_id'],
		mixins: [Navigation, ModelMixin, ContractMixin],
		data () {
			return {
				processing_conditions: false,
				columsAdd: [],
				table_busy_conditions: false,
				table_busy_type_monte: false,
				distribute_busy: false,
				form_message: '',
				processing: false,
				modal_loaded: false,
				config_table_hrefs: {
					'contract_config.horse.horse_nom': {
						routeUniqueName: 'horseFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'contract_config.horse.horse_id'
						}
					}
				},
				contract_config: [],
				horse: null,
				horses: [],
				all_horses: [],
				contract_config_type_monte: [],
				errors: [],
				details: [],
				all_type_monte: [],
				models_to_add: [],
				labelTitleModele: this.getTrad("monte.rechercher_modele"),
				labelTitleHorse: this.getTrad("horse.rechercher_cheval"),
				selectedLabel: this.getTrad("global.selected_label"),
				extern_slot_columns: ['same_as_parent'],
				events_tab: {
					"TableAction::goToExecDistributeConditions": this.execDistributeConditions,
					"TableAction::goToAddStallionToConditionsParent": this.addStallionToConditionsParent,
					'TableAction::goToArchiveContractConfigTypeMonte': (params) => {
						this.archiveContractConfigTypeMonte(params.contractconfigtypemonte_id, params.contractconfigtypemonte_archive)
					},
				},
				show_ht: true,
				vat: []
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.table_busy_type_monte = true
				this.local_season_id = this.$sync.replaceWithReplicated('season', this.$route.params.season_id)

				await this.loadConditionsSeasonContractConfig()

				this.contract_config_type_monte = await this.getConditionsFromGlobal(this.contractconfigglobaltypemonte_id)

				let all_type_monte = await this.getStallionContractConfigFromGlobal(this.contractconfigglobal_id, this.contractconfigglobaltypemonte_id)
				let details = all_type_monte.details
				let tab = this.deepClone(details)
				for (let detail_key in details) {
					for(let horses in details[detail_key]["horses"]) {
						for(let conditions in details[detail_key]["horses"][horses]["conditions"]) {
							for(let articles in details[detail_key]["horses"][horses]["conditions"][conditions]['articles_valides']) {
								if(details[detail_key]["horses"][horses]["conditions"][conditions]['articles_valides'][articles]['contractconfigconditionsarticles_ht'] == null) {
									tab[detail_key]["horses"][horses]["conditions"][conditions]['articles_valides'][articles]['contractconfigconditionsarticles_ht'] = tab[detail_key]["horses"][horses]["conditions"][conditions]['articles_valides'][articles]['articles']['articles_ht'] / 100
									tab[detail_key]["horses"][horses]["conditions"][conditions]['articles_valides'][articles]['contractconfigconditionsarticles_htunit'] = tab[detail_key]["horses"][horses]["conditions"][conditions]['articles_valides'][articles]['articles']['articles_ht'] / 100
									tab[detail_key]["horses"][horses]["conditions"][conditions]['articles_valides'][articles]['contractconfigconditionsarticles_ttc'] = tab[detail_key]["horses"][horses]["conditions"][conditions]['articles_valides'][articles]['articles']['articles_ttc'] / 100
								}
								else {
									tab[detail_key]["horses"][horses]["conditions"][conditions]['articles_valides'][articles]['contractconfigconditionsarticles_ht'] = tab[detail_key]["horses"][horses]["conditions"][conditions]['articles_valides'][articles]['contractconfigconditionsarticles_ht'] / 100
									tab[detail_key]["horses"][horses]["conditions"][conditions]['articles_valides'][articles]['contractconfigconditionsarticles_htunit'] = tab[detail_key]["horses"][horses]["conditions"][conditions]['articles_valides'][articles]['contractconfigconditionsarticles_htunit'] / 100
									tab[detail_key]["horses"][horses]["conditions"][conditions]['articles_valides'][articles]['contractconfigconditionsarticles_ttc'] = tab[detail_key]["horses"][horses]["conditions"][conditions]['articles_valides'][articles]['contractconfigconditionsarticles_ttc'] / 100
								}
							}
						}
					}

					tab[detail_key]["horses"] = _sortBy(tab[detail_key]["horses"], 'horse.horse_nom')
				}



				this.details = tab
				this.all_type_monte = all_type_monte.columns

				this.table_busy_type_monte = false

				this.vat = []

				const vat = await this.getVat()
				for (let i = 0; i < vat.length; i++) {
					this.vat.push({
						id: vat[i].vat_id,
						name: vat[i].vat_label,
						value: vat[i].vat_value,
					})
				}
			},
			async loadConditionsSeasonContractConfig(){
				let contract_config = await this.loadConditionsGlobal(this.local_season_id)
				this.contractconfigglobal_id = contract_config.contractconfigglobal_id
			},
			async execDistributeConditions(contractconfigtypemonte_ids){
				if (this.distribute_busy == true){
					return;
				}
				this.distribute_busy = true;
				let result = await this.saveDistributionConditions(contractconfigtypemonte_ids, this.contractconfigglobaltypemonte_id)
				if(result == null) {
					this.failureToast("toast.distribute_conditions_failed")
				}
				else {
					this.successToast("toast.distribute_conditions_success")
					this.$refs.table_type_monte.unselectAll()
					this.init_component()
				}
				this.distribute_busy = false;
			},

			async addStallionToConditionsParent() {
				this.modal_loaded = false
				this.$refs["modal"].show()
				this.horses = []
				this.horse = null
				this.contract_config = []
				this.all_horses = await this.getStallionTypeMonte(this.contractconfigglobaltypemonte_id)

				if(this.all_horses != null) {
					Object.keys(this.all_horses).forEach(horse => {
						this.horses.push(this.all_horses[horse].horse)
					})
				}

				this.modal_loaded = true
			},

			async archiveContractConfigTypeMonte(contractconfigtypemonte_id, actual_state) {
				try {
					await this.archiveTypeMonte(contractconfigtypemonte_id, !actual_state)
					await this.$sync.force(true, true)
					EventBus.$emit('TableAction::stopSpin')
	                await this.init_component()
				}
				catch {
					this.failureToast()
				}
			},

			async save_conditions(){
				if(!this.processing_conditions) {
					this.processing_conditions = true

					let result = await this.saveContractConfigGlobalConditionsStallions(this.contractconfigglobal_id, this.details)
					if(result != null) {
						this.successToast()
					}
					else{
						this.failureToast()
					}
					
					this.processing_conditions = false
				}
			},

			async checkForm() {
				this.errors = []
				if(this.processing == false) {
					this.processing = true

					if(!this.horse) {
						this.errors.push("horse")
					}

					if(this.errors.length > 0) {
						this.processing = false
						return false
					}

					let result = await this.saveStallionToConditionsParent(this.contractconfigglobaltypemonte_id, this.contract_config)
					if(result != null) {
						this.horses = []
						this.horse = null
						this.contract_config = []
						this.successToast()
						this.init_component()
						this.$refs["modal"].hide()
					}
					else {
						this.failureToast()
					}

					this.processing = false
				}
			},

			setHTCondition(type_monte, horse, cdt, article, value) {
				this.show_ht = false

				value = value.replace(',', '.')
				this.details[type_monte].horses[horse].conditions[cdt].articles_valides[article].contractconfigconditionsarticles_htunit = value
				this.details[type_monte].horses[horse].conditions[cdt].articles_valides[article].contractconfigconditionsarticles_ht = this.details[type_monte].horses[horse].conditions[cdt].articles_valides[article].contractconfigconditionsarticles_qte * value

				const vat = this.vat.find(vat => { return vat.id == this.details[type_monte].horses[horse].conditions[cdt].articles_valides[article].articles.articles_vat })
				const ht = parseFloat(this.details[type_monte].horses[horse].conditions[cdt].articles_valides[article].contractconfigconditionsarticles_ht)
				this.details[type_monte].horses[horse].conditions[cdt].articles_valides[article].contractconfigconditionsarticles_ttc = (vat.value * ht) + ht

				this.show_ht = true
			},

			setQteCondition(type_monte, horse, cdt, article, value) {
				this.show_ht = false

				value = value.replace(',', '.')
				this.details[type_monte].horses[horse].conditions[cdt].articles_valides[article].contractconfigconditionsarticles_qte = value
				this.details[type_monte].horses[horse].conditions[cdt].articles_valides[article].contractconfigconditionsarticles_ht = this.details[type_monte].horses[horse].conditions[cdt].articles_valides[article].contractconfigconditionsarticles_htunit * value

				const vat = this.vat.find(vat => { return vat.id == this.details[type_monte].horses[horse].conditions[cdt].articles_valides[article].articles.articles_vat })
				const ht = parseFloat(this.details[type_monte].horses[horse].conditions[cdt].articles_valides[article].contractconfigconditionsarticles_ht)
				this.details[type_monte].horses[horse].conditions[cdt].articles_valides[article].contractconfigconditionsarticles_ttc = (vat.value * ht) + ht

				this.show_ht = true
			},
			switchAvailableCa(model) {
				this.updateContractConfigConditionAvailableCa(model.contractconfigtypemonte_id, model.contractconfigtypemonte_available_ca)
			},
		},
		computed: {
			hasDedicatedCa() {
                return this.$store.state.userAccess.hasDedicatedClientArea
			},
			contract_config_type_monte_formatted(){
				let tab = []

				for (let i = 0; i < this.contract_config_type_monte.length; i++) {
					let element = this.contract_config_type_monte[i];
					element.type_monte.contracttypemonte_label = this.getTrad(this.contract_config_type_monte[i].type_monte.contracttypemonte_label)
					tab.push(element)
				}

				return tab
			},
			typeMonteAddColumns() {
				if(!this.hasDedicatedCa) return this.columsAdd
				return [...this.columsAdd, 'available_ca']
			},
			modelsAddColumns() {
				if(!this.hasDedicatedCa) return []
				return ['default_ca']
			}
		},
		watch: {
			horse(val, prec){
				this.contract_config = []

				if(val != null) {
					for (let i = 0; i < val.length; i++) {
						const contract_config = this.all_horses.find(elem => elem.horse.horse_id == val[i].horse_id)

						this.contract_config.push(contract_config.contractconfig_id)
					}
				}
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
			HeaderTab : () => import('@/components/HeaderTab'),
			DecimalInput: () => import('GroomyRoot/components/Inputs/DecimalInput')
		}
	}
</script>